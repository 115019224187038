div.dt-container .dt-paging .dt-paging-button {
  padding: 0;
  border: none !important;
  background-color: transparent !important;
  background: transparent !important;
}

.overflow-x{
  overflow-x: scroll;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  body {
    padding-top: 156px;
  }

  .search_form {
    border-radius: 0;
  }

  .search_form .d-flex {
    flex-direction: column;
  }

  .search_form .border-left {
    height: 1px !important;
    width: 100%;
    border-bottom: 1px solid #ddd;
  }

  .search_form .flex-fill {
    width: 100% !important;
  }

  .search_form .search_submit_btn {
    width: 100%;
    height: auto;
    border: 1px solid #dc3545;
  }

  .search_form .search_submit_btn button {
    width: 100%;
    border-radius: 0 !important;
    position: relative;
    top: 0;
    right: 0;
    transform: none;
  }

  .search_form .input_overlay {
    top: auto;
    bottom: 100%;
    border-radius: 0;
  }

  .search_form .autosuggest_search_box .react-autosuggest__suggestions-container,
  .search_form .autosuggest_search_box .react-autosuggest__suggestions-container ul {
    border-radius: 0;
    margin: 0;
    border-radius: 0;
  }

  .search-bar-wrapper {
    width: 100%;
    max-width: 100%;
    margin: 0px auto;
  }

  .copy {
    margin-bottom: 59px;
  }
}